(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["@bytel/react-oauth2"] = factory(require("react"), require("react-router-dom"));
	else
		root["@bytel/react-oauth2"] = factory(root["react"], root["react-router-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__297__, __WEBPACK_EXTERNAL_MODULE__146__) {
return 