import { getItem, setItem } from './helpers/storage'
import { TypeAuthState } from './index'

export const AUTH_STORAGE_KEY = 'authentification'
export const AUTH_STORAGE_METHOD = 'local'

export function getAuthState(): TypeAuthState | null {
  return getItem<TypeAuthState>(AUTH_STORAGE_KEY, AUTH_STORAGE_METHOD)
}

export function getAccessToken(): string | null {
  return getAuthState()?.accessToken || null
}

export function getIdToken(): string | null {
  return getAuthState()?.idToken || null
}

export function resetStorage() {
  let authState = getAuthState()
  setItem(
    AUTH_STORAGE_KEY,
    { ...authState, idToken: null, accessToken: null, userInfo: null, status: 'LOGIN_INIT' } as TypeAuthState,
    AUTH_STORAGE_METHOD
  )
}
