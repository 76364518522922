// import Cookies from 'js-cookie'

import { User } from '.'
import { getAuthState, resetStorage } from './authStorage'

let isLoggingIn = false

// export async function logOut() {
// if (!isLoggingIn) {
//   isLoggingIn = true
//   try {
//     clearStorage()
//     const redirectLoggedOut: string = window.location.origin + '/loggedout'
//     window.location.replace((await getAuthConfig()).logoutEndpoint + encodeURIComponent(redirectLoggedOut))
//     Cookies.remove('SSOID')
//     Cookies.remove('SSO_ACTIVE')
//   } finally {
//     isLoggingIn = false
//   }
// }
// }

export function logIn() {
  if (!isLoggingIn) {
    isLoggingIn = true
    resetStorage()
    try {
      const authConfig = getAuthState()?.config
      if (!authConfig) {
        throw new Error(`Aucune configuration pour l'authentification, utiliser la fonction setAuthConfig(config)`)
      } else {
        const clientId = '?client_id=' + authConfig.clientId
        const responseType = '&response_type=id_token%20token'
        const redirect = '&redirect_uri=' + (authConfig.redirectUri || encodeURIComponent(window.location.href))

        window.location.replace(authConfig.authorizationEndpoint + clientId + responseType + redirect)
      }
    } finally {
      isLoggingIn = false
    }
  }
  return {
    userInfo: null,
    accessToken: null,
    idToken: null,
    status: 'LOGIN_INIT'
  }
}

export function buildAuthHeaders() {
  const authState = getAuthState()
  if (authState?.testbedMode.active) {
    return {
      'X-Oauth2-UserType': authState?.userInfo?.usertype,
      'X-Oauth2-Login': authState?.userInfo?.login,
      'X-Oauth2-Scope': authState?.testbedMode.scopes.join(';'),
      'X-Oauth2-ClientId': authState?.config?.clientId,
      'X-Oauth2-Habilit': authState?.testbedMode.roles.join(';')
    }
  }
  if (authState?.accessToken) {
    return { Authorization: `Bearer ${authState?.accessToken}` }
  }
  return {}
}

export function getUserInfos(): User | null {
  const authState = getAuthState()
  return authState?.userInfo || null
}
