import JwtDecode from 'jwt-decode'
import * as React from 'react'
import { Route, RouteProps, useLocation } from 'react-router-dom'
import { User } from '.'
import AuthContext from './AuthContext'
import { getAccessToken, getIdToken } from './authStorage'
import { logIn } from './authUtils'
const AccessForbiddenComponent = React.lazy(() => import('./AccessForbidden'))

// On autorise des props inconnue en plus des props de Route pour l'écriture par component
type TypeAuthenticatedRoute = React.FC<RouteProps & { rolesAllowed?: string[]; [prop: string]: unknown }>

export const AuthenticatedRoute: TypeAuthenticatedRoute = ({
  rolesAllowed,
  component: Component,
  children,
  render: renderMethod,
  ...props
}) => {
  if (!(Component || children || renderMethod)) {
    throw new Error("Aucun composant à rendre, merci de préciser un des attributs 'component', 'children' ou 'render'")
  }
  return (
    <Route
      {...props}
      render={routeProps => (
        <AuthRedirectComponent rolesAllowed={rolesAllowed} {...props} {...routeProps}>
          <>{Component ? <Component {...props} {...routeProps} /> : renderMethod?.(routeProps) || children}</>
        </AuthRedirectComponent>
      )}
    />
  )
}

const AuthRedirectComponent: React.FC<{ rolesAllowed?: string[] }> = ({ rolesAllowed, children, ...props }) => {
  const authState = React.useContext(AuthContext)
  const location = useLocation()
  const hashParams = React.useMemo(() => getHashParams(location.hash), [location])
  const doitInitialiserAuthent = React.useMemo(() => {
    return (hashParams.access_token && authState.status !== 'LOGIN_SUCCESS') || authState.isConnexionExpiree()
  }, [hashParams, authState])

  if (doitInitialiserAuthent) {
    return <AuthInit hashParams={hashParams} />
  }

  switch (authState.status) {
    case 'LOGIN_SUCCESS':
      if (!rolesAllowed?.[0] || authState.userInfo?.roles.some(rolePossede => rolesAllowed.includes(rolePossede))) {
        return <>{children}</>
      }
      return (
        <React.Suspense fallback={<React.Fragment />}>
          <AccessForbiddenComponent rolesAllowed={rolesAllowed} />
        </React.Suspense>
      )
    case 'LOGIN_FAILED':
      return <LoginPageRedirect />
    case 'FORBIDDEN':
      return (
        <React.Suspense fallback={<React.Fragment />}>
          <AccessForbiddenComponent />
        </React.Suspense>
      )
    default:
      return <AuthInit hashParams={hashParams} />
  }
}

const LoginPageRedirect = () => {
  const { config } = React.useContext(AuthContext)

  React.useLayoutEffect(() => {
    if (config) {
      logIn()
    } else {
      console.log("Pas encore de configuration d'authentification")
    }
  }, [config])
  return <></>
}

const AuthInit: React.FC<{ hashParams: TypeHashParams }> = ({ hashParams }) => {
  const { setState, config } = React.useContext(AuthContext)

  const initOauth2 = React.useCallback(() => {
    let userInfo: User | null = null
    let accessToken = hashParams.access_token || getAccessToken()
    let idToken = hashParams.id_token || getIdToken()
    if (idToken) {
      try {
        userInfo = decodeUserInfos(idToken)
      } catch (e) {
        console.error("Erreur de decodage de l'idToken", e)
      }
    }
    if (userInfo?.tokenExpirationTime && userInfo?.tokenExpirationTime < Date.now()) {
      userInfo = null
      idToken = null
      accessToken = null
    }
    setState({
      accessToken,
      idToken,
      userInfo,
      status: userInfo != null ? 'LOGIN_SUCCESS' : 'LOGIN_FAILED'
    })
    if (hashParams.access_token) {
      redirectWithoutQuery()
    }
  }, [hashParams])

  React.useLayoutEffect(() => {
    if (config && !config.testbedMode) {
      initOauth2()
    }
  }, [config])

  return <></>
}

// #####################
// #### Utilitaires ####
// #####################

function decodeUserInfos(idToken: string | null): User | null {
  if (idToken == null) return null

  const decodedIdToken: TokenDto = JwtDecode<TokenDto>(idToken)
  let tokenExpirationTime = decodedIdToken.exp
  if (new Date(decodedIdToken.exp) < new Date(2000, 1)) {
    tokenExpirationTime *= 1000 // Le token renvoyé dans l'idToken par OAuth2 est en secondes et non en millisecondes
  }
  return {
    login: decodedIdToken.login,
    firstname: decodedIdToken.given_name,
    lastname: decodedIdToken.family_name,
    fullname: decodedIdToken.name,
    usertype: decodedIdToken.user_type,
    roles: decodedIdToken.roles,
    tokenExpirationTime
  }
}

function getHashParams(hash: string): TypeHashParams {
  const hashes: string[] = hash.slice(hash.indexOf('#') + 1).split('&')
  const params: TypeHashParams = {}
  hashes.forEach(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params
}

function redirectWithoutQuery(): void {
  // redirect to path without jwt queryParams
  document.location.replace(location.pathname + location.search)
}

type TypeHashParams = {
  access_token?: string
  token_type?: string
  id_token?: string
  expires_in?: string
}

type TokenDto = {
  login: string
  given_name: string
  family_name: string
  name: string
  user_type: string
  roles: string[]
  exp: number
}
