import { buildContext, ContextFunction } from '@bytel/context-helpers'
import * as React from 'react'
import { AUTH_STORAGE_KEY, AUTH_STORAGE_METHOD } from './authStorage'
import { TypeAuthConfig, TypeAuthContext, TypeAuthState, User } from './index'

const initState: TypeAuthState = {
  userInfo: null,
  accessToken: null,
  idToken: null,
  status: 'LOGIN_INIT',
  config: null,
  testbedMode: {
    active: false,
    scopes: [],
    roles: []
  }
}

const setState: ContextFunction<TypeAuthState> = (store, setStore, newState: Partial<TypeAuthState>) => {
  setStore({ ...initState, config: store.config, ...newState })
}

const setConfig: ContextFunction<TypeAuthState> = (state, setStore, config: TypeAuthConfig) => {
  let newState: Partial<TypeAuthState> = { config }
  if (config.testbedMode) {
    newState = {
      ...initState,
      ...newState,
      userInfo: {
        firstname: 'James',
        lastname: 'BOND',
        fullname: 'James BOND',
        login: 'jbond',
        roles: config.testbedMode.roles,
        usertype: 'CDC'
      },
      testbedMode: {
        active: true,
        roles: config.testbedMode.roles,
        scopes: config.testbedMode.scopes
      },
      status: 'LOGIN_SUCCESS'
    }
  }
  setStore(newState)
}

const setTestbedModeRoles: ContextFunction<TypeAuthState> = (store, setStore, roles: string[]) => {
  setStore({
    testbedMode: { ...store.testbedMode, roles },
    userInfo: {
      ...store.userInfo,
      roles
    } as User
  })
}

const isConnexionExpiree: ContextFunction<TypeAuthState> = store => {
  return store.userInfo?.tokenExpirationTime && store.userInfo.tokenExpirationTime < Date.now()
}

const { context: AuthContext, provider: AuthContextProvider } = buildContext<TypeAuthState, TypeAuthContext>(
  initState,
  {
    setConfig,
    setState,
    setTestbedModeRoles,
    isConnexionExpiree
  },
  AUTH_STORAGE_METHOD,
  AUTH_STORAGE_KEY
)

const ConfigProvider: React.FC<{ configProvider: () => Promise<TypeAuthConfig> }> = ({ configProvider, children }) => {
  const { setConfig } = React.useContext(AuthContext)
  React.useEffect(() => {
    configProvider().then(setConfig)
  }, [configProvider])
  return <>{children}</>
}

type TypeAuthProvider = React.FC<{ configProvider: () => Promise<TypeAuthConfig> }>
const AuthProvider: TypeAuthProvider = props => (
  <AuthContextProvider>
    <ConfigProvider {...props} />
  </AuthContextProvider>
)

export default AuthContext
export { AuthProvider }
